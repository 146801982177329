import {
    Injectable,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BrowserDetailsModel } from '../models/browserDetail.data.model';


@Injectable()
export class CommonService {

    public headers: HttpHeaders = null;
    constructor(
        private _http: HttpClient,
    ) { }
    private setHeaders(): HttpHeaders {
        var Authorization = JSON.parse(sessionStorage.getItem('config')).Authorization ;
        let apiKey = sessionStorage.getItem('ak')
        return this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('x-api-key', apiKey)
            .set('Authorization', sessionStorage.getItem('uid')?sessionStorage.getItem('uid'):Authorization);
    }
    public addHistory(historyData: object): Promise<object> {
        let headers = this.setHeaders();
        var ServiceUrl = JSON.parse(sessionStorage.getItem('config')).ServiceUrl ;
        return this._http.post(ServiceUrl + '/user_history/add_history', historyData, { headers }).toPromise();
    }

    public getBrowserDetails(): BrowserDetailsModel {
        var nVer = navigator.appVersion;
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;
    
        // In Opera, the true version is after Opera or after Version
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In MSIE, the true version is after MSIE in useAgent
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browserName = 'Microsoft Internet Explorer';
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after Chrome 
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browserName = 'Chrome';
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after Safari or after Version' 
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browserName = 'Safari';
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                fullVersion = nAgt.substring(verOffset + 8);
            }
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browserName = 'Firefox';
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
        if ((ix = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, ix);
        }
    
        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
    
        //$window.sessionStorage[BROWSER] = browserName;
        var osName = 'Not known';
        if (navigator.appVersion.indexOf('Win') !== -1) {
            osName = 'Windows';
        } else if (navigator.appVersion.indexOf('Mac') !== -1) {
            osName = 'Mac';
        } else if (navigator.appVersion.indexOf('X11') !== -1) {
            osName = 'UNIX';
        } else if (navigator.appVersion.indexOf('Linux') !== -1) {
            osName = 'Linux';
        }
        
        return {
            'Browser_Name': browserName,
            'Full_Version': fullVersion,
            'Major_Version': majorVersion,
            'OS_Name': osName,
        };
    }
}
