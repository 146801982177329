import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class Logger {
    // tslint:disable-next-line:no-empty
    public static readonly OFF: number = 0;
    public static readonly FATAL: number = 1;
    public static readonly ERROR: number = 2;
    public static readonly WARN: number = 3;
    public static readonly INFO: number = 4;
    public static readonly DEBUG: number = 5;
    public static readonly TRACE: number = 6;

    // tslint:disable-next-line:no-any
    public log(message: any[], errorLevel: number = Logger.INFO): void {
        let color;
        switch(errorLevel) {
            case Logger.TRACE:
                color = 'green';
                // tslint:disable:no-console
                console.log(`%c${this._timestamp()} Trace:`, `color:${color}`, ...message);
                break;
            case Logger.INFO :
                color = 'teal';
                console.log(`%c${this._timestamp()} Info:`, `color:${color}`, ...message);
                break;
            case Logger.DEBUG :
                color = 'blue';
                console.log(`%c${this._timestamp()} Debug:`, `color:${color}`, ...message);
                break;
            case Logger.WARN :
                console.warn(`${this._timestamp()} WARNING:`, ...message);
                break;
            case Logger.ERROR :
                color = 'black';
                console.error(`%c${this._timestamp()} ERROR:`, `color:${color}`, ...message);
                break;
            case Logger.FATAL :
                console.error(`${this._timestamp()} FATAL:`, ...message);
                break;
            default:
                console.warn('Unknown error level');
                console.log(...message);
                // tslint:enable:no-console
        }
    }

    private _timestamp(): string { return moment().format('YYYY-MM-DD HH:mm:ss.SS'); }

    // tslint:disable:no-any
    public trace(...message: any[]): void { return this.log(message, Logger.TRACE); }
    public debug(...message: any[]): void { return this.log(message, Logger.DEBUG); }
    public info(...message: any[]): void { return this.log(message, Logger.INFO); }
    public warn(...message: any[]): void { return this.log(message, Logger.WARN); }
    public error(...message: any[]): void { return this.log(message, Logger.ERROR); }
    public fatal(...message: any[]): void { return this.log(message, Logger.FATAL); }

    // static methods allowed for error and fatal, since those will always be displayed
    public static error(...message: any[]): void {
        const color = 'black';
        console.error(`%c${moment().format('YYYY-MM-DD HH:mm:ss.SS')} ERROR:`, `color:${color}`, ...message);
    }
    public static fatal(...message: any[]): void {
        console.error(`${moment().format('YYYY-MM-DD HH:mm:ss.SS')} FATAL:`, ...message);
    }
    // tslint:enable:no-any
}
