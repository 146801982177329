import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-signature',
  templateUrl: './invalid-signature.component.html',
  styleUrls: ['./invalid-signature.component.scss']
})
export class InvalidSignatureComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
